<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Valuation Requests</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Valuation Requests"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: parentRouteName }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Valuation
              Requests
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="valuation_requests"
        class="mt-4"
        show-expand
        no-data-text="No Valuation Requests found"
      >
        <template v-slot:item.property="{ item }">
          <router-link
            v-if="item.property"
            :to="{
              name: 'module-melrose-properties-individual',
              params: { propertyId: item.property.id },
            }"
            v-html="item.property.address.short"
          ></router-link>
        </template>
        <template v-slot:item.customer="{ item }">
          {{ item.customer.full_name }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    parentRouteName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      restoreDialog: {
        open: false,
        loading: false,
        valuation_requests: {},
      },
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Valuation Requests",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Customer", value: "customer" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    valuation_requests() {
      let valuation_requests =
        this.$store.getters[
          "melrose/propertiesStore/valuation_requests_archive"
        ];

      if (this.searchTerm !== "") {
        valuation_requests = valuation_requests.filter((v) => {
          const property = v.address.short.toLowerCase();
          const customer = v.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property.includes(searchTerm) || customer.includes(searchTerm);
        });
      }

      return valuation_requests;
    },
  },
};
</script>
